import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Earn Dai`}</h1>
    <p>{`Lend to borrowers, save on Oasis, get paid for work, tackle bounties, win prizes, get grant funding... There are loads of ways to earn Dai!`}</p>
    <Box sx={{
      padding: 4
    }} mdxType="Box">
      <h2>{`Popular Ways to Earn`}</h2>
      <List mdxType="List">
        <Box mdxType="Box">
          <p><strong parentName="p">{`Oasis Save`}</strong></p>
          <p>{`Users can save Dai on `}<a href="https://oasis.app">{`Oasis`}</a>{` to earn the Dai Saving Rate (DSR). The DSR smart contract, introduced with Multi-Collateral Dai, incentivizes saving. Dai locked in the DSR accrues continuously at a published annual rate.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Learn`}</strong></p>
          <p>{`Earn Dai by learning about it. Some platforms, like `}<a href="https://www.coinbase.com/earn/dai">{`Coinbase Earn`}</a>{`, offer Dai rewards for users that spend time learning about Dai and MakerDAO. `}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Work`}</strong></p>
          <p>{`Check out listings for jobs that pay in Dai and other cryptocurrencies. `}<a href="https://crypto.jobs/">{`Crypto.Jobs`}</a>{` hosts job openings for blockchain enthusiasts. Reddit channels, like `}<a href="https://www.reddit.com/r/Jobs4Crypto/">{`r/Jobs4Crypto`}</a>{` and `}<a href="http://reddit.com/r/CryptoRecruiting/">{`r/CryptoRecruiting`}</a>{`, have opportunities as well.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Bounties`}</strong></p>
          <p>{`Receive Dai for completing bounty projects. MakerDAO's `}<a href="https://github.com/makerdao/community/projects/">{`bounties`}</a>{` are open to anyone, but other platforms, like `}<a href="https://gitcoin.co/">{`Gitcoin`}</a>{` and `}<a href="https://bounties.network/">{`Bounties Network`}</a>{`, offer rewards for contributors.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Grants`}</strong></p>
          <p>{`Some organizations offer grants funded with Dai. For example, on `}<a href="https://gitcoin.co/grants/">{`Gitcoin Grants`}</a>{`, anyone can create a grant request and crowdsource fundraising. Maker’s `}<a href="https://community-development.makerdao.com/grants">{`Development Grants Program`}</a>{` is designed to directly support projects that increase Dai adoption.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Lend`}</strong></p>
          <p>{`Earn Dai by lending on platforms like `}<a href="https://trade.dydx.exchange/balances">{`dYdX`}</a>{` and `}<a href="https://instadapp.io/compound/">{`Compound`}</a>{`. Please note, there are risks associated with lending, and users are encouraged to do proper due diligence.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Hackathons`}</strong></p>
          <p>{`Participate in blockchain developer hackathons. MakerDAO’s `}<a href="/contribute/hackathons">{`Hackathon Initiative`}</a>{` regularly hosts events where winners are paid with Dai.`}</p>
        </Box>
        <Box mdxType="Box">
          <p><strong parentName="p">{`Video Game Tournaments`}</strong></p>
          <p>{`Compete for Dai in video game tournaments. `}<a href="https://axie.substack.com/p/axie-and-makerdao-present-the-infinity">{`Axie Infinity`}</a>{`, for example, recently hosted a tournament with over 2,100 Dai up for grabs!`}</p>
        </Box>
      </List>
    </Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      